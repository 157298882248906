<template>
  <div>
      <div class="header">
        <img src="../../assets/kmyx/bg1.png" alt="" />
        <img src="../../assets/kmyx/bg2.png" alt="" />
        <img src="../../assets/kmyx/bg3.png" alt="" />
      </div>
      <div class="footer">
        {{record}} 沪公网安备 31011502017406号 Copyright © 2023 版权所有 上海医健通健康科技有限公司
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      record: '沪ICP备19048066号-4'
    }
  },
  created(){
    const host = window.location.host
    if(host.indexOf('senzco.com') !== -1){
      this.record = '沪ICP备19048066号-9'
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
img{
  display: block;
  width: 100%;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 87PX;
  font-size: 13PX;
  color: rgba(255, 255, 255, .4);
  background-color: #252525;
}
</style>
